
import { defineComponent } from 'vue'
import Logo from '@/assets/images/logo.png'
export default defineComponent({
  props: {
    collapse: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    return {
      title: "常州存量房成交量统计",
      Logo
    }
  }
})
