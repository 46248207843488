
import { computed, defineComponent } from 'vue'
import SidebarItem from './SidebarItem.vue'
import SidebarLogo from './SidebarLogo.vue'
import variables from '@/styles/_variables-to-js.scss'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  components: {
    SidebarItem,
    SidebarLogo
  },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter();
    const sidebar = computed(() => {
      return store.state.app.sidebar
    })
    const routes = computed(() => router.options.routes);

    const menuActiveTextColor = computed(() => {
      return variables.menuActiveText;
    })

    const activeMenu = computed(() => {
      const { meta, path } = route
      if (meta !== null || meta !== undefined) {
        if (meta.activeMenu) {
          return meta.activeMenu
        }
      }
      return path
    })

    const isCollapse = computed(() => {
      return sidebar.value.opened
    })

    return {
      sidebar,
      routes,
      showLogo: true,
      menuActiveTextColor,
      variables,
      activeMenu,
      isCollapse
    }
  }
})
